import * as React from 'react';
import { alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #d7e7dc, #FFF)'
            : `linear-gradient(#193431, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            Welcome to&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : '#F0FEBF',
              }}
            >
              Sage
            </Typography>
          </Typography>
          <Typography
            textAlign="center"
            color="text.secondary"
            sx={{ alignSelf: 'center', width: { sm: '100%', md: '80%' } }}
          >
            Explore our cutting-edge workbench, delivering high-quality insights
            tailored to your needs. Elevate your experience with top-tier features
            and services.
          </Typography>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignSelf="center"
            spacing={1}
            useFlexGap
            sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
          >
            {/* <TextField
              id="outlined-basic"
              hiddenLabel
              size="small"
              variant="outlined"
              aria-label="Enter your email address"
              placeholder="Your email address"
              inputProps={{
                autocomplete: 'off',
                ariaLabel: 'Enter your email address',
              }}
            /> */}
            {/* <Button variant="contained" color="primary" href="https://calendly.com/d/ckz3-ct7-7rx/15-min-chat-with-usc-student">
              Schedule a Chat
            </Button> */}
            <Button variant="contained" color="primary" href="https://hellosage.vercel.app">
              Try Demo
            </Button>
            <Button
              variant="contained"
              color="text"
              href="https://calendly.com/d/ckz3-ct7-7rx/15-min-chat-with-usc-student"
              target="_blank"
              sx={{
                boxShadow: (theme) =>
                  theme.palette.mode === 'light'
                    ? '0 2px 4px 0 rgba(0, 0, 0, 0.1)'
                    : '0 2px 4px 0 rgba(255, 255, 255, 0.1)',
                '&:hover': {
                  boxShadow: (theme) =>
                    theme.palette.mode === 'light'
                      ? '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                      : '0 4px 8px 0 rgba(255, 255, 255, 0.2)',
                },
              }}
            >
              Schedule a Chat
            </Button>
          </Stack>
          {/* <Typography variant="caption" textAlign="center" sx={{ opacity: 0.8 }}>
            By clicking &quot;Start now&quot; you agree to our&nbsp;
            <Link href="#" color="primary">
              Terms & Conditions
            </Link>
            .
          </Typography> */}
        </Stack>
        <Box
          id="image"
          sx={(theme) => ({
            mt: { xs: 8, sm: 10 },
            alignSelf: 'center',
            height: { xs: 200, sm: 700 },
            width: '90%',
            backgroundImage:
              theme.palette.mode === 'light'
                ? 'url(/dashboard.png)'
                : 'url(/dashboard.png)',
            backgroundSize: 'cover',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#BFCCD9', 0.5)
                : alpha('#9CCCFC', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha('#193431', 0.2)}`
                : `0 0 24px 12px ${alpha('#193431', 0.2)}`,
          })}
        />
      </Container>
    </Box>
  );
}
