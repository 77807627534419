import React from 'react';
import './App.css';
// Assuming LandingPage.js is in the src directory
import LandingPage from './LandingPage';

function App() {
  return (
    <div className="App">
      {/* Render LandingPage component here */}
      <LandingPage />
    </div>
  );
}

export default App;
